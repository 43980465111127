import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import SectionHeading from "./section-heading";

const Events = ({ events }) => (
  <section className="section section-events" aria-labelledby="events-title">
    <div className="container">
      <SectionHeading
        id="events-title"
        title="Agenda"
        link="https://www.csemichelin.fr/123_page_culture-et-loisirs.html"
        linkLabel="Tout l’agenda"
      />
      <ul>
        {events.map((event) => (
          <li key={event.id}>
            <article className="card">
              <p className="card-title">
                <a href={event.url} target="_blank" rel="noreferrer">
                  {event.title}
                </a>
              </p>
              <p className="card-label">{event.dateRange}</p>
              {event.tags && (
                <p className="card-eyebrow">
                  {event.tags.map((tag, index) => (
                    <span key={index}>{tag}</span>
                  ))}
                </p>
              )}
              <p className="card-description">
                {event.description.description.slice(0, 150)}
                {event.description.description.length > 150 && "…"}
              </p>
              {event.image && (
                <figure className="card-image">
                  <GatsbyImage
                    image={
                      event.image.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={event.image.description || ""}
                  />
                </figure>
              )}
            </article>
          </li>
        ))}
      </ul>
      <a
        href="https://www.csemichelin.fr/calendrier_liste_evenements_agendas.html"
        target="_blank"
        rel="noreferrer"
      >
        Tout l’agenda
      </a>
    </div>
  </section>
);

export default Events;
