import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import FeaturedIssue from "../components/featured-issue";
import News from "../components/news";
import Events from "../components/events";
import Issues from "../components/issues";
import Partners from "../components/partners";

const IndexPage = ({ data }) => {
  const homePage = data.homePage;
  const news = data.articles.nodes
    .filter((article) => article.newsArticle)
    .slice(0, 3);
  const issues = data.issues.nodes;

  return (
    <Layout>
      <Seo bodyClass="home" />
      <FeaturedIssue issue={issues[0]} />
      <News
        articles={news}
        link="/actualites/"
        linkLabel="Toutes les actualités"
      />
      <Events events={homePage.events} />
      <Issues
        issues={issues}
        title="Nos derniers numéros"
        link="/numeros/"
        linkLabel="Tous les numéros"
      />
      <Partners partners={homePage.partners} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    homePage: contentfulHome {
      events {
        id
        dateRange
        tags
        title
        description {
          description
        }
        image {
          description
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 1.5
                quality: 80
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        url
      }
      partners {
        id
        title
        description
        localFile {
          publicURL
        }
      }
    }
    articles: allContentfulArticle(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        ...articleFields
        newsArticle
      }
    }
    issues: allContentfulIssue(
      limit: 4
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        ...issueFields
        intro {
          intro
        }
      }
    }
  }
`;
