import * as React from "react";
import SwiperCore, { A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import Prev from "./prev";
import Next from "./next";

SwiperCore.use([A11y, Navigation]);

const Partners = ({ partners }) => {
  const prevEl = React.useRef(null);
  const nextEl = React.useRef(null);

  return (
    <section
      className="section section-partners"
      aria-labelledby="partners-title"
    >
      <div className="container">
        <h2 id="partners-title">Nos partenaires</h2>
        <div className="section-carousel">
          <button ref={prevEl} className="section-carousel-prev">
            <Prev />
          </button>
          <button ref={nextEl} className="section-carousel-next">
            <Next />
          </button>
          <Swiper
            spaceBetween={40}
            slidesPerView={4}
            loop={partners.length > 4}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = prevEl.current;
              swiper.params.navigation.nextEl = nextEl.current;
            }}
          >
            {partners.map((partner) => (
              <SwiperSlide key={partner.id}>
                {partner.localFile && (
                  <img
                    src={partner.localFile.publicURL}
                    alt={partner.description || ""}
                    title={partner.title}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Partners;
