import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const FeaturedIssue = ({ issue }) => (
  <header className="featured-issue">
    <div className="container">
      <div className="featured-issue-text">
        <h1>{issue.title}</h1>
        <p className="eyebrow">
          N<sup>o</sup> {issue.number} – {issue.dateRange}
        </p>
        {issue.intro && <p>{issue.intro.intro}</p>}
        <Link className="button" to={`/numeros/${issue.slug}/`}>
          Découvrir le numéro
        </Link>
      </div>
      {issue.cover && (
        <figure className="featured-issue-image">
          <GatsbyImage
            image={issue.cover.localFile.childImageSharp.gatsbyImageData}
            alt={issue.cover.description || ""}
          />
        </figure>
      )}
    </div>
  </header>
);

export default FeaturedIssue;
